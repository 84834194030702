@import "../../../../../styles/colors.scss";
@import "../../../../../styles/responsiveSize";

.insert-pin-form {
  height: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  .body-pin {
    .form {
      text-align: start;
      gap: 16px;
      display: flex;
      flex-direction: column;
      .actions {
        display: flex;
        justify-content: space-between;
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $blue_gray_800;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    margin-bottom: 40px;
    .help-container {
      display: flex;
      gap: 4px;
      span {
        color: $bnp_blue_light_700;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.28px;
      }
    }
  }

  .custom-pin-input {
    width: 100%;
    margin-bottom: 20px;
    input {
      width: 25%;
      background-color: $blue_gray_100;
      input:focus {
        width: 25%;
        border-color: $bnp_blue_hard_800;

      }
    }
    button {
      min-width: 150px;
      max-width: 20%;
    }
  }
}
.pin:focus{
  border-color: $bnp_blue_hard_800 !important;
 }