@import "../../styles/colors.scss";

.step-container {
  display: flex;
  gap: 4px;
  .circle-step {
    background-color: $blue_gray_400;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: all ease 0.3s;
    &[data-variation="active"] {
      width: 16px;
      background-color: $bnp_blue_hard_800;
    border-radius: 4px;

    }
  }
}
