@import "../../styles/colors.scss";

.card {
  display: flex;
  text-align: left;
  align-items: center;

  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  transition: box-shadow 0.3s ease;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;

  &.clicked {
    border: 2px solid #079AE9;
    background: #F6FBFE;
  }

  // &.white {
  //   background: $white;
  //   border: 1px solid $blue_gray_200;
  // }
  // &.light_blue {
  //   background: $light_blue_50;
  //   border-radius: 12px;
  //   padding: 16px;
  // }
  // &.blue_gray {
  //   background: $blue_gray_100;
  //   border-radius: 12px;
  //   padding: 16px;
  // }
  // &.red {
  //   background: $red_50;
  //   border-radius: 12px;
  //   padding: 16px;
  // }
}
