@import "../../styles/index.scss";
.form-template {
  overflow: scroll;
  scroll-behavior: smooth;
  min-height: 100vh;
  max-height: 100vh;
  height: calc(
    100svh - 66px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  max-width: 100svw;
  &[data-variation="inputs"] {
    padding: 20px;
    padding-bottom: calc($FOOTER_HEIGHT + 20px);
    padding-top: calc($NAVBAR_HEIGHT + 20px);
  }
}
