@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./colors.scss";

@mixin bodyHeight() {
  height: calc(100vh - 60px - 56px - 66px);
}
@mixin bodyHeightReduced() {
  height: calc(100vh - 60px - 56px - 66px - 86px);
}
html {
  touch-action: pan-x pan-y;
  -ms-touch-action: none;
}
@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}
@mixin fontWeight {
  &[data-variation="bold"] {
    font-weight: 700;
  }
  &[data-variation="semibold"] {
    font-weight: 600;
  }
  &[data-variation="medium"] {
    font-weight: 500;
  }
  &[data-variation="regular"] {
    font-weight: 400;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100svh;
  max-height: 100svh;
  height: 100dvh; /* Fallback for browsers that do not support Custom Properties */
  max-width: 100svw;
}

* {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue_gray_800;
}


input,
select,
button,
.ant-modal-content {
  border-radius: 16px !important;
   height: 48px !important;
  font-size: 14px !important;
  font-weight: 500 !important;

}
.ant-card-head{
  padding: 16px !important;
}
.ant-card-body{
  padding: 16px !important;
}
.row {
  display: flex;
  width: 100%;
  gap: 16px;
  &.wrap {
    flex-wrap: wrap;
  }
}

.col {
  display: flex;
  flex-direction: column;
  width: calc(100% );
  &.xs {
    width: calc(25% - 16px);
  }
  &.sm {
    width: calc(33.3333% - 16px);
  }
  &.md {
    width: calc(50% - 16px);
  }
  &.lg {
    width: calc(75% - 16px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $blue_gray_800;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $blue_gray_900;
}
select {
  background: #F8F7F6 !important;
  font-size: 14px !important;
  box-shadow: none !important;
  &::placeholder {
    font-size: 14px !important;
  }
}
input {
  background: #F8F7F6 !important;
  font-size: 14px !important;
  box-shadow: none !important;

  &::placeholder {
    font-size: 14px !important;
  }

  &[data-variation="error"] {
    border-color: $red_500 !important;
    box-shadow: 0 0 0 1px $red_500 !important;
  }
  &[data-variation="successful"] {
    border-color: $teal_500 !important;
    box-shadow: 0 0 0 1px $teal_500 !important;
  }
  &::placeholder {
    color: $blue_gray_400;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &:focus-visible {
    border: 2px solid $bnp_blue_light_600 !important;

    box-shadow: none !important;
  }
}

select {
  /* cursor: pointer;
  color: $blue_gray_400 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  background-color: $white !important; */
  &[data-variation="error"] {
    border-color: $red_500 !important;
    box-shadow: 0 0 0 1px $red_500 !important;
  }
  &[data-variation="successful"] {
    border-color: $teal_500 !important;
    box-shadow: 0 0 0 1px $teal_500 !important;
  }
  &::placeholder {
    color: $blue_gray_400;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &:focus-visible {
    border: 2px solid $bnp_blue_light_600 !important;
    box-shadow: none !important;
  }

}

.custom-link {
  display: flex;
  gap: 5px;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
}
.ant-card {
  border-radius: 20px !important;
  .ant-card-body {
    padding: 24px 32px;
  }
}

#image-preview-container {
  align-content: center;
  width: 100%;
  
  img {
    margin-top: 20px;
    height: auto;
    width: 100%;
    &#image-selfie{
      object-fit: content;
    }
  }
}

#image-preview-container-selfie {
  background-color: black;
  width: 100%;
  height: 100svh;
  
  position: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover
}

.ant-card-head {
  padding: 16px !important;
}

.one-button-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-bottom: 24px;
 

  background-color: white;
  button {
    width: 100%;
    height: 48px !important;
  }
  &.background {
    background-color: white;
  }
}

$NAVBAR_HEIGHT: 66px;
$FOOTER_HEIGHT: 80px;

$CONTENT_HEIGHT: calc(100vh - $NAVBAR_HEIGHT);

$PAGE_HEADER_HEIGHT: 66px;
$BODY_HEIGHT: calc(100vh - $NAVBAR_HEIGHT - $PAGE_HEADER_HEIGHT);

$TITLES_SECTION_HEIGHT: 56px;
$SECTIONS_HEIGHT: calc($BODY_HEIGHT - $TITLES_SECTION_HEIGHT);
$FOOTER_SECTION_HEIGHT: 86px;
$SECTIONS_HEIGHT_WITH_FOOTER: calc(
  $BODY_HEIGHT - $TITLES_SECTION_HEIGHT - $FOOTER_SECTION_HEIGHT
);

// .css-147jbbr[aria-invalid=true], .css-147jbbr[data-invalid] {
//   border-bottom: 2px solid #DC2626 !important;
//   box-shadow: none !important;
// }

// .css-1f4oybn[aria-invalid=true], .css-1f4oybn[data-invalid] {
//   border-bottom: 2px solid #DC2626 !important;
//   box-shadow: none !important;
// }

.css-1sge1bu:focus-visible, .css-1sge1bu[data-focus-visible] {
  background: var(--chakra-colors-transparent);
   border-color: transparent !important; 
}

.css-19gst3r:focus-visible, .css-19gst3r[data-focus-visible] {
  z-index: 1;
  border-color:  transparent !important;

}
.css-8ylmci:focus-visible, .css-8ylmci[data-focus-visible] {
  z-index: 1;
  border-color:  transparent !important;
}