.input {
    background: #F8F7F6 !important;
    border-radius: 12px !important;
    height: 48px !important;
    padding: 12px 16px !important;

    box-shadow: none !important;

    &[aria-invalid=true] {
        border-color: #E53E3E !important;
        border: 2px solid red !important;
    }
}