@import "../../../../styles/colors.scss";
.action-icon {
  margin-top: 26px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.plus {
    background: #080541;
    color: white;
    &:hover {
        background-color: $blue_gray_800;
      border: 2px solid $blue_gray_800;

      }
      &:active {
        background-color: $blue_gray_900;
      border: 2px solid $blue_gray_900;
      }
  }
  &.delete {
    border: 2px solid $red_100;
    color: $red_500;
    &:hover {
      background-color: $red_100;
    }
    &:active {
      background-color: $red_200;
    border: 2px solid $red_200;
    }
  }
}
