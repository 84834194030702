@import "../../styles/colors.scss";

.header-steps {
  span.details {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: $blue_gray_500;
  }
  
  .edit-button {
    margin-top: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: -0.28px;
    color: $bnp_blue_light_400;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }
}

.step-option {
  border-top: 1px solid $blue_gray_100;
  padding: 18px;
  color: $blue_gray_500;
  &[data-variation="1"] {
  }
  &[data-variation="2"] {
    background-color: $bnp_blue_light_50;
    border-bottom: 1px solid $bnp_blue_light_50;
  }
  &[data-variation="3"] {
  }

  .image-step {
    width: 44px;
    height: 44px;
    color: #CAC6C3;
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;


    &[data-variation="1"] {
   
    }
    &[data-variation="2"] {

 
      color: $bnp_blue_light_200;
    }
    &[data-variation="3"] {
   
      color: $bnp_blue_light_500;
    
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; 
    letter-spacing: -0.28px;

    
    &[data-variation="1"] {
      color: $blue_gray_500;
      
    }
    &[data-variation="2"] {
      color: $blue_gray_800;
    }
    &[data-variation="3"] {
      color: $blue_gray_800;
    }
    &.block{
      color: $blue_gray_400 !important;
    }
  }

  span.tag {
    display: flex;
    padding: 1px 6px;
    align-items: center;
    gap: 2px;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;

    border-radius: 4px;
    width: max-content;
    padding: 2px 8px;

    //PENDIENTE
    &[data-variation="1"] {
     
      background:#E7E4E1;
      color: #1F1E1E;
    }
    //EMPEZAR
    &[data-variation="2"] {
   
      background: $yellow_300;
      color: $yellow_900;
    }
    //LISTO
    &[data-variation="3"] {
      
      background: $lime_300;
      color: $bnp_blue_light_50;
    }
  }

  .action-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &[data-variation="2"] {
     

      color: $bnp_blue_light_500;
    }
    &[data-variation="3"] {
      color: $bnp_blue_light_500;
    }
  }
}
