@import "../../../../../styles/colors.scss";
@import "../../../../../styles/responsiveSize";

.login-form {
  height: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .body-login {
    .header {
      margin-bottom: 94px;
    }
    .container-form {
      gap: 16px;
      display: flex;
      flex-direction: column;
      text-align: start;
      .actions {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $blue_gray_800;
        }
      }
      .chakra-checkbox__label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      align-items: center;

      .input-person {
        background-color: #F2EFED;
        border-radius: 16px !important;
        &:focus-visible {
          border: 2px solid #068BD2 !important;
          box-shadow: none !important;
        }
   
      }
    }
  }
}

.modal-content {
  .modal-content-1 {
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    gap: 10px;
    .email {
      border-bottom: 2px solid #F75C00 !important;
      box-shadow: none !important;
    }
  }
  .modal-content-2 {
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    gap: 10px;
    .phone {
      border-bottom: 2px solid #F75C00 !important;
          box-shadow: none !important;
    }
  }
}
