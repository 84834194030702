@import "../../../../../styles/colors.scss";
@import "../../../../../styles/responsiveSize";

.initial-company-form {
  height: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
.card-title{
  padding: 16px 0;
  word-wrap: break-word;
  white-space: break-spaces;
  span{
    color: $bnp_blue_light_400
  }

}
    .form {
      text-align: start;
      width: 100%;
      .chakra-checkbox__label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      align-items: center;
      .select {
        background: #F2EFED !important;        ;
      }
    }

  }

.link {
  color: $bnp_blue_light_500 !important;
  text-decoration:underline !important;
}

