@import "../../../styles/colors.scss";

.container-search-input-select {
  padding: 12px 16px;
  border-bottom: 1px solid $blue_gray_200;
  border-top: 1px solid $blue_gray_200;
}

.custom-drawerbody {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  text-align: start;
}

.container-search-input-select {
  height: 66px;
  padding: 10px;
}
.select {
  background: #F8F7F6 !important;
  border-radius: 1px;
  height: 48px !important;
}
.search {
  background: #F8F7F6 !important;
  border-radius: 1px !important ;
  height: 48px !important;
}
.options-container {
  flex: 1;
  overflow: auto;
  .select-modal-options {
    padding: 16px;
    border-bottom: 1px solid $blue_gray_200;
    display: flex;
    gap: 8px;
    font-weight: 400;
    align-items: center;
    .left {
      width: 90%;
    }
    .right {
      color: $teal_700;
    }
  }
}
