.welcome-template {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: relative;
  max-width: 100vw;
  justify-content: center;
  align-items: center;

  .logo-container {
    margin-bottom: 12px;
    padding: 20px;
    padding-bottom: 0;
  }

  .title {
    padding: 24px 20px;
    padding-bottom: 0;
    text-align: start;
  }

  .subtitle {
    text-align: start;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
  }

  .body-template {
    margin: 24px 0;
    padding: 0 20px;
    padding-bottom: 0;

    &[data-variation="borderless"] {
      padding: 0;
    }

    overflow: auto;
    max-height: calc(100vh - 240px);
  }

 
}
.footer-welcome {
  display: flex;
  align-items: center;
  gap: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 16px 20px;
  margin-bottom: 10px; // Centra horizontalmente el botón
  .button-back {
    border-radius: 100% !important;
    background-color: transparent;
    width: 50%;
    height: 48px;
    padding: 0;
 
  }

}