@import "../../../../styles/colors.scss";

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.body {
  @include center();
  text-align: center;
  border: 1px solid #e2e8f0;
  border-radius: 32px;
  padding: 72px 0;
  .head-container {
    margin: 20px;
    @include center();
    gap: 6px;
    svg{
      margin-bottom: 16px;
    }
    .title-conf {
      font-size: 20px;
      color: #04072F;
      font-weight: 500;
    }
    .text-conf {
      font-size: 14px;
      color: #64748B;
      font-weight: 400;
    }
  }
  .middle-container {
    padding: 32px 0;
    width: 100%;
    border-top: 1px solid $blue_gray_200;
    @include center();

    .tramite {
      font-size: 14px;
      color: #64748B;
      font-weight: 600;
      margin-bottom: 25px;
    }
  }
  .bottom-container {
    padding-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include center();
  }
}

@media (max-width: 600px) {
  .body {
    @include center();
    text-align: center;
    border: 1px solid #e2e8f0;
    border-radius: 32px;
    padding: 20px 0;
    .head-container {
      margin: 20px;
      @include center();
      gap: 6px;
      svg{
        margin-bottom: 16px;
      }
      .title-conf {
        font-size: 20px;
        color: #04072F;
        font-weight: 500;
      }
      .text-conf {
        font-size: 14px;
        color: #64748B;
        font-weight: 400;
      }
    }
    .middle-container {
      padding: 32px 0;
      width: 100%;
      border-top: 1px solid $blue_gray_200;
      @include center();
  
      .tramite {
        font-size: 14px;
        color: #64748B;
        font-weight: 600;
        margin-bottom: 25px;
      }
    }
    .bottom-container {
      padding-top: 32px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include center();
    }
  }
}