.radio {
    background: white ;

   &:hover {
    border-color: #2A94D0 !important;
    background: #2A94D0!important;
   }

}

.css-vtg3j9[aria-checked=true], .css-vtg3j9[data-checked] {
    border-color: #2A94D0 !important;
    background: #2A94D0!important;
    color: var(--chakra-colors-white);
}