@import "../../../../styles/colors.scss";

.ocr-step {
  padding-top: 30px;
height: 100vh;


  .link {

    color: $bnp_blue_light_400;

    &:hover {
      text-decoration: underline;
    }
  }

  .col {
    border-bottom: 1px solid #E2E8F0;

  }

  .label {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    color: #94A3B8;
  }

  .datos {
    color: #080541;
    font-weight: 600;
  }
}

.footer-doc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: white;

  .circle-doc {
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E2E8F0;
  }

  .si-correcto {
    border-radius: 16px !important;
    width: 100vw;
    font-size: 14px;
    height: 48px;
  }
}