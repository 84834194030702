@import '../../../../styles/index.scss';

.natural-client-form {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 25px;
/*   padding: 20px;
  padding-top: calc($NAVBAR_HEIGHT + 20px);
  padding-bottom: calc($FOOTER_HEIGHT + 20px); */
  gap: 8px;
  padding-bottom: calc($FOOTER_HEIGHT + 20px);
}
