.container-discleimer {
    border-radius: 16px;
    border: 1px solid #E7E4E1;
    padding: 16px;
    margin-bottom: 100px;

    .disclaimer {
        font-size: 12px !important;
        font-weight: 400;
        text-align: justify;
        display: flex;
        align-items: center;

        .dis {

            .text-check {
                font-size: 14px;
                font-weight: 400;
            }
        }

    }

    .text-dis {
        padding-top: 10px;
        padding-left: 25px;
        font-size: 12px;
        font-weight: 400;
        text-align: justify;
    }
}
.container-discleimer {
    border-radius: 16px;
    border: 1px solid #E7E4E1;
    padding: 16px;
    margin-bottom: 100px;

    .disclaimer {
        font-size: 12px !important;
        font-weight: 400;
        text-align: justify;
        display: flex;
        align-items: center;

        .dis {

            .text-check {
                font-size: 14px;
                font-weight: 400;
            }
        }

    }

    .text-dis {
        padding-top: 10px;
        padding-left: 25px;
        font-size: 12px;
        font-weight: 400;
        text-align: justify;
    }
}
.container-discleimer-com {
    border-radius: 16px;
    border: 1px solid #E7E4E1;
    padding: 16px;


    .disclaimer {
        font-size: 12px !important;
        font-weight: 400;
        text-align: justify;
        display: flex;
        align-items: center;

        .dis {

            .text-check {
                font-size: 14px;
                font-weight: 400;
            }
        }

    }

    .text-dis {
        padding-top: 10px;
        padding-left: 25px;
        font-size: 12px;
        font-weight: 400;
        text-align: justify;
    }
}
