 /* {
  font-size: 100%;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  color: white;
} */

html {
  height: 100vh;
  width: 100%;
  background-color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  width: inherit;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  touch-action: manipulation;
}

button {
  border: transparent ;
  border-radius: 16px !important;
}

.audit-trail-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 1000;
}

.audit-trail-image {
  margin-top: 10px;
  max-width: 200px;
  max-height: auto;
}

#face-logo {
  margin-top: 20px;
  max-width: 200px;

}

#controls {
  width: 640px;
  height: 500px;
  padding: 20px 0;
  max-width: 100%;
  margin: 0 auto;

  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  background-color: transparent;
  
  text-align: center;
  align-items: center;
  
}

.big-button {
  width: 100% !important;
  height: 48px;

  color: white;
  background:  #079AE9;
  border-radius: 16px;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  cursor: pointer;
  outline: none;
}

.medium-button {
  width: 100%;
  height: 30px;
  margin: 10px auto 0;
  color: white;
  background:  #FF9A5E;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-out;
  cursor: pointer;
  outline: none;
}

.big-button:disabled, .big-button[disabled],
.medium-button:disabled, .medium-button[disabled] {
  cursor: not-allowed;
  background: #079AE9 !important;
  color: #079AE9 !important;
}

@media (hover: hover) {
  .big-button:hover:enabled,
  .medium-button:hover:enabled {
    background-color: #079AE9;
    color: white !important;
    transition: all 0.3s ease-out;
  }
}

#status {
  color: transparent;
  margin: 20px auto 0;
  display: none;
  font-size: 18px;
  background-color: transparent;
  width: 90%;
  display: block;
  border-radius: 4px;
  padding: 8px;
}

.wrapping-box-container {
  position: fixed;
  margin: 0 auto;
  width: 100%;
  height: auto;
  /* color: #fff; */
  padding: 10px;
  transition: opacity 800ms;
}

button:disabled {
  cursor: not-allowed;
  color: #ddd;
  border-color: #ddd;
}

a {
  text-decoration: none;
  color: inherit;
}


.circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

}

@media (max-width:768px) {
  #controls {
    width: 350px;
    height: 500px;
    justify-content: center;
    display: flex;
    text-align: center;
    align-items: center;
    
  }
  .big-button {
    width:60%;


  }
  .circle {
    width: 80px;
    height: 80px;
  
  
  }
}
#custom-logo-container{
  width: 10px;
  z-index: 1;
}

.Toastify__toast-body > div:last-child {
  text-align: start !important;
}