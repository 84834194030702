.main-template {
  overflow: scroll; 

  /*   padding: 20px; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  max-width: 100svw;

  .logo-container {
    width: 200px;
    margin-bottom: 12px;
    padding: 20px;
    padding-bottom: 0;
  }
  .title {
    padding: 24px 20px;
    padding-bottom: 0;
    text-align: start;
  }
  .subtitle {
    text-align: start;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;

  }
  .body-template {
    margin: 24px 0;
    padding: 0 20px;
    padding-bottom: 0;
    &[data-variation="borderless"] {
      padding: 0;
    }
    overflow: auto;
    max-height: calc(100vh - 240px);
  }
}

@media (max-width: 600px) { 
  .logo-container {
margin-top: 20px;
  }
  .title {
   margin-top: 20px;
  }
}