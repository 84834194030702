.take-photo-container {
  .camMask-doc {
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}

.imgprev {
  object-fit: contain;
  height: auto;
}

.three-scroll-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  justify-content: center;
  align-items: center;
  text-align: center;
  .container-preview {
   margin-top: -10%;
    justify-content: center;
height: 90vh;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;

    .image-preview {
      width: 100%;
      object-fit: cover;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Sombra para dar un efecto de profundidad
      margin-bottom: 10px;
    }
    .button-back {
      background-color: #079ae9;
      width: 45%;
      margin-top: 10px;
      color: white;
      margin-left: 25%;
    }
  }
}

.container-preview-back {
  height: 130vh;
  margin-top: -80%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;

  .image-preview {
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Sombra para dar un efecto de profundidad
  }
}

.camera-container {
  padding: 20px;
}

.container-doc {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-top: 25%;

  .text-doc {
    color: #94a3b8;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 20%;
  }
}

.container-documentos {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 24px 20px;
  color: #94a3b8;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15%;
  align-items: center;
}
.title-doc {
  color: #484746;
  font-size: 14px;
  flex-grow: 1;
  text-align: left;
  margin-left: 15px;
}
