@import "../../../../../styles//colors.scss";
.container-pass {

  margin-top: 25%;
  margin-left: 20px;
  margin-right: 20px;
  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    color: #64748b;
    .cod {
      font-size: 14px;
      font-weight: 500;
      color: #04072f;
    }
    .user-user {
      display: flex;
      align-items: center;
    }
  }

  .title-data {
    justify-content: flex-start;
    display: flex;

    font-size: 16px;
    font-weight: 600;
    color: #04072f;
  }
  .form-password {
    display: flex;
    flex-direction: column;


    .password-form {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;

      .password-card {
  
        width: 100%;
        margin-top: 10px;
        .input-group {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-start;
          align-items: flex-start;

          .label-pass {
            font-size: 14px;
            font-weight: 500;
            color: #04072f;
          }
          .input-error {
  border: 1px solid red;
}
        }
        .input-group-pass {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 10px;
          .label-pass {
            font-size: 14px;
            font-weight: 500;
            color: #04072f;
          }
          .input-error {
            border: 1px solid red;
          }
        }
      }
    }
  }
}

.text-error-pass {
  font-weight: 400;
  justify-content: flex-start;
  display: flex;
  text-align: left;
  margin-top: 10px;
}
