@import "../../../../styles/colors.scss";
.form-footer {
  width: 100%;

  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  &[data-variation="visible"] {
    background-color: white;
  }

  .repit-buttons {

    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    button:nth-child(1){
      border-radius: 50% !important;
    }
  }

  .greybutton {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.274);
    input {
      display: none;
    }
    &.big {
      width: 74px;
      height: 74px;
    }
  }

  .circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    color: white;

    background: #079AE9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.grey {
      width: 40px;
      height: 40px;
      background-color: black;
      &:hover {
        background-color: #3b3b3b;
      }
      &:active {
        background-color: #7a7a7a;
      }
    }

    &:hover {
      background-color: $bnp_blue_light_400;
    }
    &:active {
      background-color: $bnp_blue_light_700;
    }
  }
}
